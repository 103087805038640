/* eslint-disable no-use-before-define */
/* eslint-disable no-useless-concat */
/* eslint-disable prefer-template */
/* eslint-disable spaced-comment */
/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useRef, useState } from 'react';
// import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { useSessionState } from '@beelineloans/cx-library/src/state/stateProvider';
import SiteCopy from '@beelineloans/cx-library/src/utils/site-copy';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import {
  NumberedListWithImage,
  VideoWithHeading,
  TextWithChecklistImg,
  PlantAndCoffee,
  Celebrate,
  ReviewsWithSocial,
  SocialProof,
  LoanGuideConvo,
  TextAndImageV2
} from '@beelineloans/cx-library/src/components/layout/panels';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import Box from '@beelineloans/cx-library/src/images/box.png';
import { Button } from '@beelineloans/cx-library/src/components/buttons';
import { Paragraph } from '@beelineloans/cx-library/src/components/typography';
import ChatHeader from '../../components/chat/ChatHeader';

const AskButton = styled(Button)`
  margin-top: 40px;
`;

const ChatTemplate = styled(NavOnlyTemplate)`
  transition: all 0.3s ease-in-out;

  @media only screen and (max-width: 767px) {
    ${(props) =>
      props.$fixed &&
      css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 20;
        box-shadow: 0 0px 16px rgba(0, 0, 76, 15%);
      `};
  }
`;

const Chat = () => {
  const [, dispatch] = useSessionState();
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const videoPanel = useRef();

  const numberedList = [
    {
      number: 1,
      noBorder: false,
      ...SiteCopy.numberedList.Radical
    },
    {
      number: 2,
      noBorder: false,
      ...SiteCopy.numberedList.OutsideTheBox
    },
    {
      number: 3,
      noBorder: false,
      ...SiteCopy.numberedList.CertaintyUpfront
    },
    {
      number: 4,
      noBorder: true,
      ...SiteCopy.numberedList.LoanGuide
    }
  ];

  const askButtonClick = () => {
    dispatch({
      type: CONST.ACTIONS.CONTACT_FORM_OPEN,
      contactForm: {
        open: true,
        type: 'contact'
      }
    });
  };

  return (
    <ChatTemplate headerProps={{ options: { background: Theme.colours.background.nude } }} $fixed={isHeaderFixed}>
      <SEO
        path={CONST.LINKS.MAIN.HOME}
        title="Loans to help anyone build a real estate empire."
        description=" Meet our AI chatbot — it’ll guide you to a painless application, loads of loan options and a super speedy closing."
        antiFlicker
      />
      <ChatHeader onHeaderFixed={setIsHeaderFixed} />
      <SocialProof dark />
      <NumberedListWithImage
        sideHeading={
          <>
            Home loans like
            <br />
            never before.
          </>
        }
        items={numberedList}
      />
      <VideoWithHeading heading={<span ref={videoPanel}>Watch the trailer and see&nbsp;it&nbsp;in&nbsp;action.</span>} videoSrc={CONST.VIDEOS.VOOMLY.EXPLAINER_PURCHASE} voomly />
      <TextAndImageV2
        h2={
          <>
            Got an outside of the box case?
            <br />
            No problem.
          </>
        }
        image={{
          src: Box,
          mobileWidth: '315px',
          title: 'Got an outside of the box case? No problem.',
          desktopWidth: '483px',
          alignment: 'center'
        }}
        left
      >
        <Paragraph>
          Maybe you’ve got the kind of situation other lenders put in the ‘too hard’ basket.
          <br />
          <br />
          So the thought of going through an application gives you hives — you just want to know if you can get a loan, or not.
          <br />
          <br />
          Good news! We embrace the quirks.
          <br />
          <br />
          We’ve got dozens of different loan types, and Loan Guides who aren’t afraid to use them.
          <br />
          <br />
          Get in touch with a Loan Guide who’ll go through their bag of tricks to find a loan that works for you.
        </Paragraph>
        <AskButton onClick={askButtonClick}>Ask a Loan Guide</AskButton>
      </TextAndImageV2>
      <ReviewsWithSocial googleRating={process.env.GATSBY_GOOGLE_REVIEW_NUM} bbbRating={process.env.GATSBY_BBB_REVIEW_NUM} />
      <TextWithChecklistImg heading="Get the green light, not a guesstimate.">
        <Paragraph>
          Our Purchase-Ready Approval uses actual numbers that are collected and verified instantly so it’s more reliable than a typical pre-approval. <br />
          <br />
          What does that mean for you? Certainty and confidence — take it house shopping and make offers knowing it’s not going to disappoint later.
          <br />
          <br />
          Yours is waiting for you, rates and all.
        </Paragraph>
      </TextWithChecklistImg>
      <LoanGuideConvo />
      <Celebrate />
      <PlantAndCoffee heading="Not sure if you’re ready? That’s OK." chatButtonLink={null} chatButtonClick={askButtonClick}>
        <Paragraph light fade>
          After an effortless application, you’ll know exactly where you stand — otherwise book a
          <br />
          chat with one of our Loan Guides to find out more.
        </Paragraph>
      </PlantAndCoffee>
    </ChatTemplate>
  );
};

Chat.propTypes = {};

Chat.defaultProps = {};

export default Chat;
