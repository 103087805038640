// SVG images have shared texture file.
// Also use same classes which causes issues of styles overriding each other and images looking wrong.
// Uses forked svg-react-loader to add filename prefix to classes to stop issues
// Don't think background texture works in background images....

import React from 'react';
import styled from 'styled-components';
import Template from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';
// import LeafInline from '@beelineloans/cx-library/src/images/svg1/leafs.inline.svg';
// import CactusInline from '@beelineloans/cx-library/src/images/svg1/cactus.inline.svg';
// import KeyInline from '@beelineloans/cx-library/src/images/svg1/key.inline.svg';

// import Img1 from '@beelineloans/cx-library/src/images/svg.inline/celebration-flower-pot.svg';
// import Img2 from '@beelineloans/cx-library/src/images/svg.inline/celebration-food.svg';
// import Img3 from '@beelineloans/cx-library/src/images/svg.inline/celebration-glass.svg';
// import Img4 from '@beelineloans/cx-library/src/images/svg.inline/chair.svg';
// import Img5 from '@beelineloans/cx-library/src/images/svg.inline/checklist.svg';
// import Img6 from '@beelineloans/cx-library/src/images/svg.inline/cracker.svg';
// import Img7 from '@beelineloans/cx-library/src/images/svg.inline/glasses.svg';
// import Img8 from '@beelineloans/cx-library/src/images/svg.inline/Homely-1.svg';
// import Img9 from '@beelineloans/cx-library/src/images/svg.inline/Homely-2.svg';
// import Img10 from '@beelineloans/cx-library/src/images/svg.inline/Homely-10.svg';
// import Img11 from '@beelineloans/cx-library/src/images/svg.inline/Homely-11.svg';
// import Img12 from '@beelineloans/cx-library/src/images/svg.inline/Homely-12.svg';
// import Img13 from '@beelineloans/cx-library/src/images/svg.inline/Homely-13.svg';
// import Img14 from '@beelineloans/cx-library/src/images/svg.inline/Homely-14.svg';
// import Img15 from '@beelineloans/cx-library/src/images/svg.inline/Homely-15.svg';
// import Img16 from '@beelineloans/cx-library/src/images/svg.inline/Homely-16.svg';
// import Img17 from '@beelineloans/cx-library/src/images/svg.inline/Homely-3.svg';
// import Img18 from '@beelineloans/cx-library/src/images/svg.inline/Homely-4.svg';
// import Img19 from '@beelineloans/cx-library/src/images/svg.inline/Homely-5.svg';
// import Img20 from '@beelineloans/cx-library/src/images/svg.inline/Homely-6.svg';
// import Img21 from '@beelineloans/cx-library/src/images/svg.inline/Homely-7.svg';
// import Img22 from '@beelineloans/cx-library/src/images/svg.inline/Homely-8.svg';
// import Img23 from '@beelineloans/cx-library/src/images/svg.inline/Homely-9.svg';
// import Img24 from '@beelineloans/cx-library/src/images/svg.inline/key.svg';
// import Img25 from '@beelineloans/cx-library/src/images/svg.inline/leafs.svg';
// import Img26 from '@beelineloans/cx-library/src/images/svg.inline/pencil.svg';
// import Img27 from '@beelineloans/cx-library/src/images/svg.inline/pot-modified.svg';
// import Img28 from '@beelineloans/cx-library/src/images/svg.inline/pot.svg';

// import Img30 from '@beelineloans/cx-library/src/images/svg.inline/tea.svg';
// import Img31 from '@beelineloans/cx-library/src/images/svg.inline/welcome.svg';
// import Img32 from '@beelineloans/cx-library/src/images/svg.inline/apple.svg';
// import Img33 from '@beelineloans/cx-library/src/images/svg.inline/bear.svg';
// import Img34 from '@beelineloans/cx-library/src/images/svg.inline/bird-head-1.svg';
// import Img35 from '@beelineloans/cx-library/src/images/svg.inline/bird-head-2.svg';
// import Img36 from '@beelineloans/cx-library/src/images/svg.inline/bird-head-3.svg';
// import Img37 from '@beelineloans/cx-library/src/images/svg.inline/bird-head-4.svg';
// import Img38 from '@beelineloans/cx-library/src/images/svg.inline/bird-head-5.svg';
// import Img39 from '@beelineloans/cx-library/src/images/svg.inline/bird-head-6.svg';
// import Img40 from '@beelineloans/cx-library/src/images/svg.inline/bird1.svg';
// import Img41 from '@beelineloans/cx-library/src/images/svg.inline/bird2.svg';
// import Img42 from '@beelineloans/cx-library/src/images/svg.inline/bird3.svg';
// import Img43 from '@beelineloans/cx-library/src/images/svg.inline/bird4.svg';

// import Img45 from '@beelineloans/cx-library/src/images/svg.inline/cactus.svg';
// import Img46 from '@beelineloans/cx-library/src/images/svg.inline/cat-1.svg';
// import Img47 from '@beelineloans/cx-library/src/images/svg.inline/cat-2.svg';
// import Img48 from '@beelineloans/cx-library/src/images/svg.inline/cat-3.svg';
// import Img49 from '@beelineloans/cx-library/src/images/svg.inline/cat-4.svg';
// import Img50 from '@beelineloans/cx-library/src/images/svg.inline/cat-5.svg';
// import Img51 from '@beelineloans/cx-library/src/images/svg.inline/cat.svg';
// import Img52 from '@beelineloans/cx-library/src/images/svg.inline/celebration-ballons.svg';
// import Img53 from '@beelineloans/cx-library/src/images/svg.inline/celebration-delivery-box.svg';

// import Img60 from '@beelineloans/cx-library/src/images/svg/scenes/scene-1.svg';
// import Img61 from '@beelineloans/cx-library/src/images/svg.inline/scene-1.svg';
// import Img62 from '@beelineloans/cx-library/src/images/svg.inline/scene-2.svg';
// import Img63 from '@beelineloans/cx-library/src/images/svg.inline/scene-3.svg';
// import Img64 from '@beelineloans/cx-library/src/images/svg.inline/scene-4.svg';
// import Img65 from '@beelineloans/cx-library/src/images/svg.inline/scene-5.svg';
// import Img66 from '@beelineloans/cx-library/src/images/svg.inline/scene-6.svg';
// import Img67 from '@beelineloans/cx-library/src/images/svg.inline/scene-7.svg';
// import Img68 from '@beelineloans/cx-library/src/images/svg.inline/scene-8.svg';
// import Img69 from '@beelineloans/cx-library/src/images/svg.inline/scene-9.svg';

import Img70 from '@beelineloans/cx-library/src/images/svg/beeline-plant-illustrations.svg';
// import Img71 from '@beelineloans/cx-library/src/images/svg/beeline-plant-2-illustrations.svg';
// import Img72 from '@beelineloans/cx-library/src/images/plant.png';

const Back = styled(Container)`
	height: 700px;
	width: 700px;
	color: #000;
	background: url(${Img70});
	background-size: contain;
	background-position: bottom center;
	background-blend-mode: hard-light;
	background-repeat: no-repeat;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;

const Col = styled.div`

`;

const ImageTest = () => (
  <Template>
    <SEO />
    <Back>This is a background image</Back>
    <Container>
      {/* <KeyInline width="400" />
      <CactusInline width="400" />
      <LeafInline height="400" /><br /> */}

      {/* <Img1 width="250" />
      <Img2 width="250" />
      <Img3 width="250" />
      <Img4 width="250" />
      <Img5 width="250" />
      <Img6 width="250" />
      <Img7 width="250" />
      <Img8 width="250" />
      <Img9 width="250" />
      <Img10 width="250" />
      <Img11 width="250" />
      <Img12 width="250" />
      <Img13 width="250" />
      <Img14 width="250" />
      <Img15 width="250" />
      <Img16 width="250" />
      <Img17 width="250" />
      <Img18 width="250" />
      <Img19 width="250" />
      <Img20 width="250" />
      <Img21 width="250" />
      <Img22 width="250" />
      <Img23 width="250" />
      <Img24 width="250" />
      <Img25 width="250" />
      <Img26 width="250" />
      <Img27 width="250" />
      <Img28 width="250" />

      <Img30 width="250" />
      <Img31 width="250" />
      <Img32 width="250" />
      <Img33 width="250" />
      <Img34 width="250" />
      <Img35 width="250" />
      <Img36 width="250" />
      <Img37 width="250" />
      <Img38 width="250" />
      <Img39 width="250" />
      <Img40 width="250" />
      <Img41 width="250" />
      <Img42 width="250" />
      <Img43 width="250" />

      <Img45 width="250" />
      <Img46 width="250" />
      <Img47 width="250" />
      <Img48 width="250" />
      <Img49 width="250" />
      <Img50 width="250" />
      <Img51 width="250" />
      <Img52 width="250" />
      <Img53 width="250" />

      <Img61 width="250" />
      <Img62 width="250" />
      <Img63 width="250" />
      <Img64 width="250" />
      <Img65 width="250" />
      <Img66 width="250" />
      <Img67 width="250" />
      <Img68 width="250" />
      <Img69 width="250" /> */}

      <Grid>
        <Col>SVG</Col>
        <Col>PNG</Col>
        <Col><img src={Img70} width="250" alt="Img70" /></Col>
        <Col>&nbsp;</Col>
        <Col>SVG</Col>
        <Col>PNG</Col>
        {/* <Col><img src={Img71} width="250" alt="Img71" /></Col>
        <Col><img src={Img72} width="250" alt="Img72" /></Col> */}
      </Grid>

      <br />

    </Container>
  </Template>
);

export default ImageTest;
