module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"beeline","lang":"en-us","accessToken":"MC5ZOUNWWHhFQUFDOEFKQ1dJ.77-977-977-977-977-977-9Bu-_vRTvv71u77-977-977-977-9DULvv73vv73vv70677-9BxLvv73vv70PTe-_vUrvv71a","customTypesApiToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoibWFjaGluZTJtYWNoaW5lIiwiZGJpZCI6ImJlZWxpbmUtMTA5ZTBlZmMtYzMzYS00MTQ3LTg4ZGUtNzcxM2U2OTkxYTk1XzUiLCJkYXRlIjoxNjc0NjE0MTg5LCJkb21haW4iOiJiZWVsaW5lIiwiaWF0IjoxNjc0NjE0MTg5fQ.cY1jrixRXm3B2BZtSpRJLzPT2uYc3KNo2QNGDJ77HJE","promptForAccessToken":true,"apiEndpoint":"https://beeline.cdn.prismic.io/api/v2","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P4D7H3Q","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"source":"static"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","icon":"../shared/images/favicon.png","cache_busting_mode":"query","theme_color_in_head":false,"background_color":"#00004C","theme_color":"#FFFAEE","legacy":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bc0d4dedcf708e18774bdf91d65a95ad"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"beeline","promptForAccessToken":true,"apiEndpoint":"https://beeline.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
