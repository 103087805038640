import React from 'react';
import { Footer } from '@beelineloans/cx-library/src/components/layout/footers/Footer';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import GlobalStyle from '@beelineloans/cx-library/src/theme/global-styles';
import { ThemeProvider } from 'styled-components';

const FooterOnly = () =>
  <ThemeProvider theme={Theme}><GlobalStyle /><Footer /></ThemeProvider>;

export default FooterOnly;
