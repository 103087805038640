import React, { useRef } from 'react';
import Template from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import { StartApplication, RatesHelp, CalculatorLegals } from '@beelineloans/cx-library/src/components/layout/panels';
import Calculator from '@beelineloans/cx-library/src/components/forms/pureCalculator/Calculator';

const RefiPureCalcDemo = () => {
  const shiftFocus = useRef();
  const headlineBlock = 'The magic calculator!';

  return (
    <Template
      headline={headlineBlock}
      subHeadline="See exactly how much better off you’ll be — just enter your address."
      headerProps={{
        options: {
          videoType: 'refi',
          voomly: true
        }
      }}
    >
      <script src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}&libraries=places`} />
      <SEO isRefi path="/rates/refi-pure-calc-demo" title="The magic calculator!" description="See exactly how much better off you’ll be — just enter your address." antiFlicker />
      <div ref={shiftFocus}>
        <Calculator />
      </div>
      <RatesHelp />
      <StartApplication isPurchase={false} />
      <CalculatorLegals />
    </Template>
  );
};

export default RefiPureCalcDemo;
